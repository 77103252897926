import {
  Button,
  Icon,
  Table,
  Tooltip,
} from "@harpercollins/harpercollins-design-system";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";
import { NavigationContext } from "@django-bridge/react";
import React, { ReactNode, useCallback } from "react";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowUpRightFromSquare,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AnalysisJobCompetitor } from "../../../types";
import ModalWindow from "../../../../lib/components/ModalWindow";
import {
  DateCell,
  NumberCell,
  TextCell,
  CurrencyCell,
  HeaderCell,
} from "../../../../lib/components/TableCells/TableCells";

interface AnalysisJobCompetitorsProps {
  competitors: AnalysisJobCompetitor[];
  add_competitor_url: string;
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  align-items: flex-start;

  button {
    @media print {
      display: none;
    }
  }
`;

function ActionsCell({ row }: { row: { original: AnalysisJobCompetitor } }) {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);

  const renderModalWindow = useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  const getTooltipContent = (original: AnalysisJobCompetitor) => {
    if (original.user_added) {
      return "Competitor added manually";
    }
    if (original.justification_summary) {
      return original.justification_summary;
    }
    return "No justification provided";
  };

  return (
    <div>
      <Tooltip placement="left" title={getTooltipContent(row.original)}>
        <Icon icon={faInfoCircle} colour="grey" />
      </Tooltip>
      <Button
        type="button"
        kind="basic"
        aria-label="Remove competitor"
        onClick={() =>
          openOverlay(row.original.remove_url, renderModalWindow, {
            onClose: () => {
              // Refresh the context when the modal is closed so the changes take effect
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshProps();
            },
          })
        }
      >
        <Icon colour="grey" icon={faTrashCan} />
      </Button>
    </div>
  );
}
const StyledCoverImage = styled.img`
  width: 100%;
  max-width: 50px;
  height: auto;
  object-fit: cover;
`;
function CoverCell({ row }: { row: { original: AnalysisJobCompetitor } }) {
  return (
    <StyledCoverImage
      src={row.original.competitor.cover_image_url}
      alt={row.original.competitor.title}
      width="50"
    />
  );
}

function TitleCell({ row }: { row: { original: AnalysisJobCompetitor } }) {
  const { amazon_url: amazonUrl, title } = row.original.competitor;
  return amazonUrl ? (
    <a href={amazonUrl} target="_blank" rel="noreferrer">
      {title} <Icon icon={faArrowUpRightFromSquare} size="xsmall" />
    </a>
  ) : (
    title
  );
}

function CompetitorsHeader({
  add_competitor_url,
}: {
  add_competitor_url: string;
}) {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);
  const renderModalWindow = useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );
  return (
    <HeaderWrapper>
      <p>
        The following list of competition titles has been provided by using
        competitors metadata and AI. Competitors' metadata is not always great
        so some titles might be off. You can remove them from the list by
        clicking the “bin” icon. You can also add new titles if they are missing
        by clicking on the “Add Competitors” button. Follow the steps and it
        will be added to the list below.
      </p>
      <Button
        type="button"
        onClick={() =>
          openOverlay(add_competitor_url, renderModalWindow, {
            onClose: () => {
              // Refresh the context when the modal is closed so the changes take effect
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshProps();
            },
          })
        }
      >
        Add Competitors
      </Button>
    </HeaderWrapper>
  );
}

function TotalSalesHeader() {
  return (
    <HeaderCell
      label="Total Sales"
      tooltip="Total sales shown are for the last 4 years only of the most recent paperback & hardback editions (if available)"
    />
  );
}

function Competitors({
  competitors,
  add_competitor_url,
}: AnalysisJobCompetitorsProps) {
  const columnHelper = createColumnHelper<AnalysisJobCompetitor>();

  const columns = [
    columnHelper.accessor("competitor.cover_image_url", {
      header: () => "Cover",
      cell: CoverCell,
      size: 80,
      enableSorting: false,
    }),
    columnHelper.accessor("competitor.title", {
      header: () => "Title",
      cell: TitleCell,
      size: 160,
    }),

    // author
    columnHelper.accessor("competitor.author", {
      header: () => "Author",
      cell: TextCell,
      size: 100,
    }),
    columnHelper.accessor("competitor.pub_date_hardcover", {
      header: () => "HB Pub Date",
      cell: DateCell,
      size: 100,
      sortingFn: "datetime",
    }),
    columnHelper.accessor("competitor.pub_date_paperback", {
      header: () => "PB Pub Date",
      cell: DateCell,
      size: 100,
      sortingFn: "datetime",
    }),
    columnHelper.accessor("competitor.total_units", {
      header: () => "Total Units",
      cell: NumberCell,
      size: 80,
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("competitor.total_sales", {
      header: TotalSalesHeader,
      cell: CurrencyCell,
      size: 80,
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("competitor.hardcover_units", {
      header: () => "HB Units",
      cell: NumberCell,
      size: 80,
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("competitor.hardcover_sales", {
      header: () => "HB Sales",
      cell: CurrencyCell,
      size: 80,
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("competitor.paperback_units", {
      header: () => "PB Units",
      cell: NumberCell,
      size: 80,
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("competitor.paperback_sales", {
      header: () => "PB Sales",
      cell: CurrencyCell,
      size: 80,
      sortingFn: "alphanumeric",
    }),
    columnHelper.display({
      id: "actions",
      header: () => "Actions",
      cell: ActionsCell,
      size: 100,
    }),
  ];

  return (
    <div>
      <CompetitorsHeader add_competitor_url={add_competitor_url} />
      <Table
        id="competitors"
        columns={columns as ColumnDef<unknown, unknown>[]}
        data={competitors}
        // next={next}
        // page={page}
        // pageCount={total_pages}
        // pageSize={pageSize}
        // previous={previous}
        // totalCount={count}
      />
    </div>
  );
}

export default Competitors;
