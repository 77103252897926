/* eslint-disable react/require-default-props */

import React, { ReactElement } from "react";
import styled from "styled-components";
import { Link, OverlayContext } from "@django-bridge/react";
import { Button, Heading } from "@harpercollins/harpercollins-design-system";

import { URLsContext } from "../../../contexts";

const FormActionsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const Paragraph = styled.p``;

interface ManuscriptAfterCreateViewContext {
  detail_url: string;
  job_failed_to_start?: boolean;
}

function ManuscriptAfterCreateView({
  detail_url,
  job_failed_to_start,
}: ManuscriptAfterCreateViewContext): ReactElement {
  const { requestClose } = React.useContext(OverlayContext);
  const urls = React.useContext(URLsContext);

  return (
    <div>
      <Heading as="h2">Manuscript uploaded</Heading>

      {job_failed_to_start && (
        <Paragraph>
          Analysis of your manuscript could not be started. Please try again
          later.
        </Paragraph>
      )}

      {!job_failed_to_start && (
        <Paragraph>
          Analysis of your manuscript has been started. This should take up to 5
          minutes.
        </Paragraph>
      )}

      <FormActionsWrapper>
        <Button type="link" as={Link} href={detail_url}>
          View manuscript
        </Button>
        <Button type="link" as={Link} href={urls.manuscript_create}>
          Upload another manuscript
        </Button>
        <Button
          type="button"
          kind="secondary"
          onClick={(e) => {
            e.preventDefault();
            requestClose({ skipDirtyFormCheck: true });
          }}
        >
          Close
        </Button>
      </FormActionsWrapper>
    </div>
  );
}
export default ManuscriptAfterCreateView;
