/* eslint-disable react/no-danger, react/prop-types */

import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { Button, Icon } from "@harpercollins/harpercollins-design-system";
import { FormWidgetChangeNotificationContext } from "@django-bridge/react";
import { faCheck, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import useForwardRef from "../../../../customHooks";
import { bytesToSize } from "../../../../utils/utils";

const StyledFileInput = styled.div`
  display:flex;
  flex-direction:column;
  width: 100%;
  justify-content:center;
  align-items:center;
  font-size: var(--font-size--medium));

  button {
    color: var(--color--primary);
    background-color: transparent;
    border:none;
    padding:0;
  }

  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }

  &.monospace {
    font-family: var(--font-family--monospace);
  }

  &:focus {
    border: 1px solid var(--color--primary);
    outline: 1px solid var(--color--primary);
  }

`;

const FileUploadPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing--medium);
  border: 2px dashed var(--color--grey-line);
  padding: var(--spacing--large) var(--spacing--medium);
  width: 100%;
  border-radius: var(--border-radius--medium);
  &.active {
    background: var(--color--grey-lightest);
    border: 2px dashed var(--color--primary);
  }
  .field-has-error & {
    border: 1px solid var(--color--red);
  }
`;

const UploadedFiles = styled.ul`
  width: 100%;
  li {
    display: flex;
    align-items: center;
    gap: var(--spacing--medium);
    padding: var(--spacing--small) var(--spacing--medium);
    border: 1px solid var(--color--grey-line);
    border-radius: var(--border-radius--medium);
    margin-bottom: var(--spacing--medium);
  }
`;

const DeleteButton = styled(Button)`
  margin-left: auto;
`;

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  maxFileSizeDisplay?: string;
}

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      onChange: originalOnChange,
      maxFileSizeDisplay,
      ...props
    }: FileInputProps,
    ref,
  ): ReactElement => {
    // Format allowed extensions (eg, ".pdf, .docx or .txt")
    let allowedExtensionsDisplay = "";
    if (props.accept) {
      const allowedExtensions = props.accept?.split(",") || [];
      allowedExtensionsDisplay = `${allowedExtensions
        .slice(undefined, -1)
        .join(", ")} or ${allowedExtensions.slice(-1).join("")}`;
    }

    const [files, setFiles] = useState<FileList | File[] | null>(null);
    const [dragActive, setDragActive] = useState<boolean>(false);
    const forwardedRef = useForwardRef(ref);
    const changeNotification = React.useContext(
      FormWidgetChangeNotificationContext,
    );

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const uploadedFiles = e.dataTransfer.files;
      setFiles(uploadedFiles);

      // update file input
      const fileInput = forwardedRef.current;
      if (fileInput) {
        fileInput.files = uploadedFiles;
      }
    };

    return (
      <StyledFileInput
        onDragLeave={handleDrag}
        onDragEnter={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {files && files.length > 0 ? (
          <UploadedFiles>
            {Array.from(files).map((file, idx) => (
              <li key={file.name}>
                <Icon icon={faCheck} colour="success" />
                <div>
                  <div>{file.name}</div>
                  <small>{bytesToSize(file.size)}</small>
                </div>
                <DeleteButton
                  type="button"
                  kind="basic"
                  aria-label="Delete manuscript"
                  onClick={() => {
                    const newFiles = Array.from(files);
                    if (newFiles.length === 1) {
                      setFiles(null);
                    } else {
                      newFiles.splice(idx, 1);
                      setFiles(newFiles);
                    }
                  }}
                >
                  <Icon colour="grey" icon={faTrashCan} />
                </DeleteButton>
              </li>
            ))}
          </UploadedFiles>
        ) : (
          <FileUploadPlaceholder className={dragActive ? "active" : ""}>
            <Icon icon={faFileUpload} size="large" />
            <p>
              Drag and drop your file or{" "}
              <Button
                type="button"
                kind="basic"
                onClick={() => {
                  if (forwardedRef.current) {
                    return forwardedRef.current.click();
                  }
                  return null;
                }}
              >
                browse
              </Button>
            </p>
            <small>
              {allowedExtensionsDisplay && (
                <>File must be in {allowedExtensionsDisplay} format.</>
              )}
              {maxFileSizeDisplay && <> Max file size {maxFileSizeDisplay}.</>}
            </small>
          </FileUploadPlaceholder>
        )}
        <input
          hidden
          type="file"
          ref={forwardedRef}
          accept=".pdf,.docx,.txt"
          onClick={(e) => {
            e.currentTarget.value = "";
          }}
          onChange={(e) => {
            setFiles(e.target.files);
            if (originalOnChange) {
              originalOnChange(e);
            }
            changeNotification();
          }}
          {...props}
        />
      </StyledFileInput>
    );
  },
);

export default FileInput;
