import styled from "styled-components";
import {
  Heading,
  Icon,
  TextBlock,
  Tooltip,
} from "@harpercollins/harpercollins-design-system";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DetailedAnalysisResult, KPI } from "../../../types";
import ScoreBadge from "../../components/ScoreBadge";
import { trafficLightRanges } from "../../../../constants";

interface ScoringProps {
  scoring: DetailedAnalysisResult["scoring"];
}

const ScoringHeader = styled.div`
  background-color: var(--color--grey-lightest);
  padding: var(--spacing--medium);
  padding-bottom: var(--spacing--small);
  border-radius: var(--border-radius--small);
  margin-bottom: var(--spacing--medium);
`;

const ScoringHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: var(--spacing--medium);
  grid-row-gap: var(--spacing--medium);
  margin-bottom: var(--spacing--medium);
  text-align: center;
`;

const FeaturedKPIWrapper = styled.div`
  background-color: var(--color--grey-lightest);
  padding: var(--spacing--medium);
  border-radius: var(--border-radius--small);
  margin-bottom: var(--spacing--large);
`;

const KPIWrapper = styled.div`
  margin-bottom: var(--spacing--large);

  &:not(:last-child) {
    padding-bottom: var(--spacing--large);
    border-bottom: 1px solid var(--color--grey-borders);
  }
`;

const KPIHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing--medium);
`;

const KPIContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing--small);
`;

function splitKpis(
  kpis: KPI[],
  keys: { featured: string[]; footer: string[] },
): [KPI[], KPI[], KPI[]] {
  const featured: KPI[] = [];
  const footer: KPI[] = [];
  const standard: KPI[] = [];

  kpis.forEach((kpi) => {
    if (keys.featured.includes(kpi.category)) {
      // push to featured array based on the order of the keys.featured array
      featured[keys.featured.indexOf(kpi.category)] = kpi;
    } else if (keys.footer.includes(kpi.category)) {
      // push to footer array based on the order of the keys.footer array
      footer[keys.footer.indexOf(kpi.category)] = kpi;
    } else {
      standard.push(kpi);
    }
  });

  return [featured, footer, standard];
}

function Scoring({ scoring }: ScoringProps) {
  const {
    kpis,
    variables: {
      tone,
      genre,
      sub_genre: subGenre,
      time_period: timePeriod,
      type_of_content: typeOfContent,
    },
  } = scoring;

  const featuredKPIList = {
    featured: [
      "Overall score",
      "Potential Commercial Success in the UK",
      "Editing Effort",
    ],
    footer: ["Potential Commercial Success in the US"],
  };

  const [featuredKPIs, footerKPIs, standardKPIs] = splitKpis(
    kpis,
    featuredKPIList,
  );

  const renderKPIs = (KPIArray: KPI[]) =>
    KPIArray.map((kpi) => (
      <KPIWrapper key={kpi.kpi_number}>
        <KPIHeading>
          <ScoreBadge
            value={kpi.score}
            format="percentage"
            trafficLightRanges={trafficLightRanges.score}
          />
          <Heading as="h3">
            {kpi.category}{" "}
            {kpi.tooltip ? (
              <Tooltip placement="top" title={kpi.tooltip}>
                <Icon icon={faInfoCircle} colour="grey" size="small" />
              </Tooltip>
            ) : null}
          </Heading>
        </KPIHeading>
        <KPIContent>
          <TextBlock
            label="Justification"
            content={kpi.justification}
            size="medium"
            inline
          />
          <TextBlock
            label="Improvement areas"
            content={kpi.improvement_areas}
            size="medium"
            inline
          />
        </KPIContent>
      </KPIWrapper>
    ));

  return scoring ? (
    <div>
      <ScoringHeader>
        <ScoringHeaderGrid>
          <TextBlock label="Genre" content={genre} size="medium" />
          <TextBlock label="Sub Genre" content={subGenre} size="medium" />
          <TextBlock label="Tone" content={tone} size="medium" />{" "}
          <TextBlock label="Time Period" content={timePeriod} size="medium" />
          <TextBlock
            label="Type of Content"
            content={typeOfContent}
            size="medium"
          />
        </ScoringHeaderGrid>
      </ScoringHeader>
      {Array.isArray(kpis) && kpis.length > 0 ? (
        <>
          {Array.isArray(featuredKPIs) && featuredKPIs.length > 0 && (
            <FeaturedKPIWrapper>{renderKPIs(featuredKPIs)}</FeaturedKPIWrapper>
          )}
          {Array.isArray(standardKPIs) &&
            standardKPIs.length > 0 &&
            renderKPIs(standardKPIs)}
          {Array.isArray(footerKPIs) && footerKPIs.length > 0 && (
            <FeaturedKPIWrapper>{renderKPIs(footerKPIs)}</FeaturedKPIWrapper>
          )}
        </>
      ) : (
        <div>KPIs not available</div>
      )}
    </div>
  ) : (
    <div>Scoring not available</div>
  );
}

export default Scoring;
