/* eslint-disable react/require-default-props */

import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import {
  Button,
  Heading,
  Breadcrumb,
  Icon,
  Table,
} from "@harpercollins/harpercollins-design-system";
import { Link, NavigationContext } from "@django-bridge/react";
import { faPlusCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import HeaderWrapper, {
  HeaderCommands,
  HeaderTitle,
} from "../../../lib/components/HeaderWrapper";
import BaseLayout from "../../../lib/components/BaseLayout";
import { Team } from "../../types";
import ModalWindow from "../../../lib/components/ModalWindow";
import { CurrentWorkspaceContext, URLsContext } from "../../../contexts";
import { TextCell } from "../../../lib/components/TableCells/TableCells";

const LockIconWrapper = styled.span`
  margin-right: var(--spacing--xsmall);

  svg {
    display: inline-block;
    color: var(--color--grey);
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing--small);
`;
function ActionsCell<TData extends Team, TValue>({
  row,
}: CellContext<TData, TValue>): ReactElement {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);
  const renderModalWindow = React.useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  return (
    <ButtonGroup>
      <Button
        type="button"
        size="small"
        kind="secondary"
        onClick={() =>
          openOverlay(row.original.edit_url, renderModalWindow, {
            onClose: () => {
              // Refresh the context when the modal is closed so the changes take effect
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshProps();
            },
          })
        }
      >
        Edit
      </Button>
      {row.original.is_scriptscan_managed && (
        <Button
          type="button"
          kind="basic"
          size="small"
          aria-label="Delete group"
          onClick={() =>
            openOverlay(row.original.delete_url, renderModalWindow, {
              onClose: () => {
                // Refresh the context when the modal is closed so the changes take effect
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                refreshProps();
              },
            })
          }
        >
          <Icon colour="grey" icon={faTrashCan} />
        </Button>
      )}
    </ButtonGroup>
  );
}

interface TeamListViewContext {
  teams: Team[];
}
const columnHelper = createColumnHelper<Team>();

function NameCell({ row }: CellContext<Team, unknown>): ReactElement {
  return (
    <>
      {!row.original.is_scriptscan_managed && (
        <LockIconWrapper>
          <Icon icon={faLock} size="xsmall" />
        </LockIconWrapper>
      )}
      {row.original.name}{" "}
      <div>
        {!row.original.is_scriptscan_managed && row.original.external_name}
      </div>
    </>
  );
}

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    size: 200,
    cell: NameCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("users_count", {
    header: "Number of users",
    size: 80,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.display({
    id: "actions",
    header: "Actions",
    cell: ActionsCell,
    size: 80,
  }),
];

function TeamListView({ teams }: TeamListViewContext): ReactElement {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);
  const urls = React.useContext(URLsContext);
  const workspace = React.useContext(CurrentWorkspaceContext);

  const renderModalWindow = React.useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  return (
    <BaseLayout>
      <HeaderWrapper>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link href={urls.manuscript_list}>← Manuscripts</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{workspace?.name} Settings</Breadcrumb.Item>
        </Breadcrumb>

        <HeaderTitle>
          <Heading as="h1">Teams</Heading>
          <HeaderCommands>
            <Button
              type="button"
              kind="primary"
              onClick={(e) => {
                e.preventDefault();
                openOverlay(urls.team_create, renderModalWindow, {
                  onClose: () => {
                    // Refresh the context when the modal is closed so the new group is visible
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    refreshProps();
                  },
                });
              }}
            >
              <Icon icon={faPlusCircle} />
              Add Team
            </Button>
            <Button
              type="link"
              as={Link}
              kind="secondary"
              href={urls.submittable_list}
            >
              → Submittable
            </Button>
          </HeaderCommands>
        </HeaderTitle>
      </HeaderWrapper>

      <Table
        id="users-list"
        columns={columns as ColumnDef<unknown, unknown>[]}
        data={teams}
        pageable={false}
      />
    </BaseLayout>
  );
}
export default TeamListView;
