import { ReactElement } from "react";
import { css, styled } from "styled-components";
import constants from "../../../../constants";
import { TrafficLights, TrafficLightsExtended } from "../../../types";

// Get colour value based on percentage
export const getColourValue = ({
  kind,
  percentage,
  trafficLightRanges,
}: {
  kind: "default" | "danger" | "traffic-light";
  percentage: number;
  // trafficLightRanges is only required if kind === "traffic-light"
  trafficLightRanges: TrafficLights | TrafficLightsExtended;
}) => {
  const colours = {
    primary: "var(--color--primary)",
    danger: "var(--color--danger)",
    red: "var(--color--red)",
    orange: "var(--color--orange-dark)",
    green: "var(--color--green-dark)",
  };

  const colourValue = () => {
    if (kind === "traffic-light") {
      // get colour based on percentage for given traffic light ranges
      const colour = Object.entries(trafficLightRanges).find(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ([, range]) => percentage >= range.min && percentage <= range.max,
      )?.[0];

      if (colour === "red") {
        return colours.red;
      }
      if (colour === "redLow") {
        return colours.red;
      }
      if (colour === "orange") {
        return colours.orange;
      }

      if (colour === "green") {
        return colours.green;
      }
      if (colour === "greenHigh") {
        return colours.green;
      }
      return "#d7d7d7";
    }

    if (kind === "danger") {
      return colours.danger;
    }
    if (kind === "default") {
      return colours.primary;
    }
    return "#d7d7d7";
  };

  return colourValue();
};

export interface ScoreBadgeProps {
  value: number;
  size?: "xsmall" | "small" | "medium" | "large";
  format: "percentage" | "score"; // percentage = 0-100, score = 0-10
  trafficLightRanges: TrafficLights;
  basic?: boolean;
}

const sizes = {
  xsmall: css`
    width: 2.3rem;
    height: 2.3rem;
    font-size: var(--font-size--xsmall);
  `,
  small: css`
    width: 2.5rem;
    height: 2.5rem;
    font-size: var(--font-size--small);
  `,
  medium: css`
    width: 4rem;
    height: 4rem;
    font-size: var(--font-size--medium);
  `,
  large: css`
    width: 5rem;
    height: 5rem;
    font-size: var(--font-size--large);
  `,
};

export const ScoreBadgeWrapper = styled.div<{
  $colour: string;
  $size: ScoreBadgeProps["size"];
  $basic: boolean;
}>`
  background-color: ${({ $colour }) => $colour};
  display: flex;
  flex-shrink: 0;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size--large);
  font-weight: bold;
  border-radius: 50%;
  margin-right: var(--spacing--medium);
  white-space: nowrap;

  ${({ $size }) => sizes[$size || "medium"]};

  // replace color with colour prop if basic is true
  ${({ $basic, $colour }) =>
    $basic
      ? css`
          color: ${$colour};
          background-color: transparent;
          padding: initial;
          border: 0;
          border-radius: 0;
        `
      : null}
`;

const isPercentage = (percentage: number) =>
  percentage >= 0 && percentage <= 100;

const isValue0to10 = (value: number) => value >= 0 && value <= 10;

const formattedValue = (value: number, format: ScoreBadgeProps["format"]) => {
  if (format === "percentage") {
    if (isPercentage(value)) {
      return `${String(value.toFixed(1))}%`;
    }
    // error if percentage is not between 0 to 100
    // eslint-disable-next-line no-console
    console.error(`Percentage must be between 0 to 100. Got ${value}`);
  }

  if (format === "score") {
    if (isValue0to10(value)) {
      return `${String(value)}/10`;
    }
    // error if value is not between 0 to 10
    // eslint-disable-next-line no-console
    console.error(`Score must be between 0 to 10. Got ${value}`);
  }

  return constants.NULL_DISPLAY_VALUE;
};

function ScoreBadge({
  value = 0,
  size = "medium",
  format = "score",
  trafficLightRanges,
  basic = false,
}: ScoreBadgeProps): ReactElement {
  const colour = getColourValue({
    kind: "traffic-light",
    percentage: Math.round(value),
    trafficLightRanges,
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <ScoreBadgeWrapper
      className="score-badge"
      $colour={colour}
      $size={size}
      $basic={basic}
    >
      {value !== null && value !== undefined
        ? formattedValue(value, format)
        : constants.NULL_DISPLAY_VALUE}
    </ScoreBadgeWrapper>
  );
}
export default ScoreBadge;
