/* eslint-disable react/require-default-props */

import React, { ReactElement, ReactNode, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Heading,
  Breadcrumb,
  Icon,
  Table,
} from "@harpercollins/harpercollins-design-system";
import { Form, Link, NavigationContext } from "@django-bridge/react";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import HeaderWrapper, {
  HeaderCommands,
  HeaderTitle,
} from "../../../lib/components/HeaderWrapper";
import BaseLayout from "../../../lib/components/BaseLayout";
import { Submittable, SubmittableForm } from "../../types";
import ModalWindow from "../../../lib/components/ModalWindow";
import { CSRFTokenContext, URLsContext } from "../../../contexts";
import {
  DateCell,
  RelativeTimeCell,
  StatusCell,
  TextCell,
} from "../../../lib/components/TableCells/TableCells";
import { Timer } from "../../../manuscripts/types";

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing--small);
`;
function ActionsCell<FData extends SubmittableForm, SValue>({
  row,
}: CellContext<FData, SValue>): ReactElement {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);
  const csrfToken = React.useContext(CSRFTokenContext);
  const renderModalWindow = React.useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  return (
    <ButtonGroup>
      <Form action={row.original.trigger_url} method="post">
        <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
        <Button type="submit" size="small" kind="secondary">
          Run
        </Button>
      </Form>
      <Button
        type="button"
        size="small"
        kind="secondary"
        onClick={() =>
          openOverlay(row.original.configure_url, renderModalWindow, {
            onClose: () => {
              // Refresh the context when the modal is closed so the changes take effect
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshProps();
            },
          })
        }
      >
        Configure
      </Button>
      <Button
        type="button"
        kind="basic"
        size="small"
        aria-label="Delete Submittable Form"
        onClick={() =>
          openOverlay(row.original.delete_url, renderModalWindow, {
            onClose: () => {
              // Refresh the context when the modal is closed so the changes take effect
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshProps();
            },
          })
        }
      >
        <Icon colour="grey" icon={faTrashCan} />
      </Button>
    </ButtonGroup>
  );
}

interface SubmittableFormsViewContext {
  submittable: Submittable;
  forms: SubmittableForm[];
}
const columnHelper = createColumnHelper<SubmittableForm>();

const columns = [
  columnHelper.accessor("form_name", {
    header: "Name",
    size: 150,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("teams", {
    header: "Teams",
    size: 150,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    cell: StatusCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("last_run", {
    header: "Last Run",
    size: 80,
    cell: RelativeTimeCell,
    sortingFn: "datetime",
  }),
  columnHelper.accessor("last_download", {
    header: "Last Download",
    size: 80,
    cell: RelativeTimeCell,
    sortingFn: "datetime",
  }),
  columnHelper.accessor("created_by", {
    header: "Created By",
    size: 80,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("created_at", {
    header: () => "Created At",
    cell: DateCell,
    size: 100,
    sortingFn: "datetime",
  }),
  columnHelper.display({
    id: "actions",
    header: "Actions",
    cell: ActionsCell,
    size: 180,
  }),
];

function SubmittableFormsView({
  submittable,
  forms,
}: SubmittableFormsViewContext): ReactElement {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);
  const urls = React.useContext(URLsContext);

  const renderModalWindow = React.useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  // Refresh once per 5 seconds to updates statuses
  useEffect(() => {
    let timeout: Timer | null = null;

    const scheduleRefreshContext = () => {
      // eslint-disable-next-line no-void
      timeout = setTimeout(() => void refreshProps(), 5000);
    };

    scheduleRefreshContext();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [submittable, refreshProps]);

  return (
    <BaseLayout>
      <HeaderWrapper>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link href={urls.manuscript_list}>← Manuscripts</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={urls.submittable_list}>Submittable</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Submittable Forms</Breadcrumb.Item>
        </Breadcrumb>

        <HeaderTitle>
          <Heading as="h1">{submittable.name}: Forms</Heading>
          <HeaderCommands>
            <Button
              type="button"
              kind="primary"
              onClick={(e) => {
                e.preventDefault();
                openOverlay(submittable.add_form_url, renderModalWindow, {
                  onClose: () => {
                    // Refresh the context when the modal is closed so the new group is visible
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    refreshProps();
                  },
                });
              }}
            >
              <Icon icon={faPlusCircle} />
              Register Submittable Form
            </Button>
          </HeaderCommands>
        </HeaderTitle>
      </HeaderWrapper>

      <Table
        id="submittable-forms-list"
        columns={columns as ColumnDef<unknown, unknown>[]}
        data={forms}
        pageable={false}
        // pageCount={}
        // pageSize={}
        // previous={}
        // totalCount={}
      />
    </BaseLayout>
  );
}
export default SubmittableFormsView;
