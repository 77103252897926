import {
  Button,
  Icon,
  RelativeTime,
  TextBlock,
} from "@harpercollins/harpercollins-design-system";
import { Link } from "@django-bridge/react";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { BiblioContent } from "../ManuscriptDetail.style";
import { Manuscript, DetailedAnalysisResult } from "../../../types";
import constants from "../../../../constants";

interface BibliographicProps {
  manuscript: Manuscript<DetailedAnalysisResult>;
}
function Bibliographic({ manuscript }: BibliographicProps) {
  return (
    <BiblioContent>
      <TextBlock label="Title" content={manuscript.title} />
      <TextBlock
        label="Author"
        content={
          manuscript.author.canonical_name
            ? manuscript.author.canonical_name
            : constants.NULL_DISPLAY_VALUE
        }
      />
      <TextBlock
        label="Category"
        content={
          manuscript.category?.name
            ? manuscript.category.name
            : constants.NULL_DISPLAY_VALUE
        }
      />
      <TextBlock
        label="Project"
        content={
          manuscript.project ? manuscript.project : constants.NULL_DISPLAY_VALUE
        }
      />
      <TextBlock
        label="Source"
        content={
          manuscript.source.display
            ? manuscript.source.display
            : constants.NULL_DISPLAY_VALUE
        }
      />
      <TextBlock
        label="Submitted"
        content={<RelativeTime time={manuscript.created_at} />}
      />
      <TextBlock
        label="Submitted By"
        content={
          manuscript.created_by
            ? manuscript.created_by
            : constants.NULL_DISPLAY_VALUE
        }
      />
      <TextBlock
        label="Manuscript File"
        content={
          <Button
            type="link"
            as={Link}
            kind="secondary"
            href={manuscript.file.download_url}
            download
          >
            <Icon icon={faFileArrowDown} />
            {manuscript.file.name}
            <span>{manuscript.file.size.display}</span>
          </Button>
        }
      />
    </BiblioContent>
  );
}
export default Bibliographic;
