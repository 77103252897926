/* eslint-disable react/no-danger, react/prop-types */

import React, { ReactElement } from "react";
import styled from "styled-components";
import TextInput from "../TextInput";

const DateInputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
`;

const InputWrapper = styled.div`
  input {
    max-width: 6.5ex;
  }

  &.year input {
    max-width: 8.5ex;
  }
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 3px;
  font-size: 13px;
  font-weight: 400;
  color: var(--color--indigo);
`;

// https://design-system.service.gov.uk/components/date-input/
function DateInput({
  id = "",
  name,
  defaultValue,
  onChangeValue = () => {},
}: React.InputHTMLAttributes<HTMLInputElement> & {
  onChangeValue?: (newDate: string) => void;
}): ReactElement {
  let defaultYear = "";
  let defaultMonth = "";
  let defaultDay = "";
  if (defaultValue) {
    const valueSplit = defaultValue.toString().split("-");
    if (valueSplit.length === 3) {
      [defaultYear, defaultMonth, defaultDay] = valueSplit;
    }
  }

  const [year, setYear] = React.useState(defaultYear);
  const [month, setMonth] = React.useState(defaultMonth);
  const [day, setDay] = React.useState(defaultDay);

  const value = React.useMemo(
    () =>
      year.length || month.length || day.length
        ? `${year}-${month}-${day}`
        : "",
    [day, month, year],
  );

  React.useEffect(() => {
    onChangeValue(value);
  }, [value, onChangeValue]);

  return (
    <DateInputWrapper id={id}>
      <input type="hidden" name={name} value={value} />

      <InputWrapper className="day">
        <InputLabel htmlFor={`${id}-day`}>Day</InputLabel>
        <TextInput
          id={`${id}-day`}
          inputMode="numeric"
          pattern="[0-9]*"
          value={day}
          maxLength={2}
          onChange={(e) => setDay(e.target.value.replace(/\D/g, ""))}
        />
      </InputWrapper>
      <InputWrapper className="month">
        <InputLabel htmlFor={`${id}-month`}>Month</InputLabel>
        <TextInput
          id={`${id}-month`}
          inputMode="numeric"
          pattern="[0-9]*"
          value={month}
          maxLength={2}
          onChange={(e) => setMonth(e.target.value.replace(/\D/g, ""))}
        />
      </InputWrapper>
      <InputWrapper className="year">
        <InputLabel htmlFor={`${id}-year`}>Year</InputLabel>
        <TextInput
          id={`${id}-year`}
          inputMode="numeric"
          pattern="[0-9]*"
          value={year}
          maxLength={4}
          onChange={(e) => setYear(e.target.value.replace(/\D/g, ""))}
        />
      </InputWrapper>
    </DateInputWrapper>
  );
}

export default DateInput;
