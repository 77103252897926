import React, { ReactElement } from "react";
import DateInput from "../../components/widgets/DateInput";
import { WidgetDef } from "./base";

export default class DateInputDef implements WidgetDef {
  idForLabel: string;

  className: string;

  constructor(idForLabel: string, className: string) {
    this.idForLabel = idForLabel;
    this.className = className;
  }

  render(
    id: string,
    name: string,
    disabled: boolean,
    value: string,
  ): ReactElement {
    return (
      <DateInput
        id={id}
        name={name}
        defaultValue={value}
        disabled={disabled}
        className={this.className}
      />
    );
  }

  getIdForLabel(id: string): string {
    return this.idForLabel.replace("__ID__", id);
  }
}
