import { Icon } from "@harpercollins/harpercollins-design-system";
import {
  faCircleNotch,
  faClock,
  faCheckCircle,
  faTriangleExclamation,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Analysis } from "../../../types";

function FailedIcon() {
  return <Icon colour="danger" icon={faCircleExclamation} />;
}

function InvalidIcon() {
  return <Icon colour="warning" icon={faTriangleExclamation} />;
}

function SuccessIcon() {
  return <Icon colour="green" icon={faCheckCircle} />;
}

function RunningIcon() {
  return <Icon colour="primary" icon={faCircleNotch} spin />;
}

function PendingIcon() {
  return <Icon colour="grey" icon={faClock} />;
}

interface StatusProps {
  status: Analysis["status"]["code"];
}

const STATUS_ICONS_MAP = {
  default: <>-</>,
  new: <PendingIcon />,
  pending: <PendingIcon />,
  running: <RunningIcon />,
  succeeded: <SuccessIcon />,
  failed: <FailedIcon />,
  invalid: <InvalidIcon />,
} as const;

function Status({ status = "pending" }: StatusProps) {
  return (
    <div title={status} aria-label={status}>
      {STATUS_ICONS_MAP[status]}
    </div>
  );
}

export default Status;
