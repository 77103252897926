import React, { useContext } from "react";
import {
  Layout,
  ToastMessages,
} from "@harpercollins/harpercollins-design-system";
import { MessagesContext } from "@django-bridge/react";
import ScriptScanAppBar from "../ScriptScanAppBar";
import Container from "../Container/Container";

function BaseLayout({ children }: React.PropsWithChildren) {
  const { messages } = useContext(MessagesContext);
  return (
    <Layout header={<ScriptScanAppBar />}>
      <Container>{children}</Container>
      <ToastMessages messages={messages} />
    </Layout>
  );
}

export default BaseLayout;
