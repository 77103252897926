/* eslint-disable react/require-default-props */

import React, { ReactElement } from "react";
import styled from "styled-components";
import { Button, Divider } from "@harpercollins/harpercollins-design-system";
import { Form, OverlayContext } from "@django-bridge/react";
import Container from "../../lib/components/Container";
import FormDef from "../../forms/adapters/Form";
import { User } from "../types";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const HeaderWrapper = styled.div``;

const Avatar = styled.img`
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
`;
const NameWrapper = styled.h1`
  font-size: 38px;
  font-weight: 800;
  color: var(--color--indigo);
  margin-top: 20px;
`;

const MessageWrapper = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #707070;
  margin-top: 20px;
`;

const FormActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  margin-top: 100px;
  margin-bottom: 40px;
`;

interface UserProfileViewContext {
  user: User;
  csrfToken: string;
  form: FormDef;
  actionUrl: string;
}

function UserProfileView({
  user,
  csrfToken,
  form,
  actionUrl,
}: UserProfileViewContext): ReactElement {
  const { overlay, requestClose } = React.useContext(OverlayContext);

  const rendered = (
    <>
      <HeaderWrapper>
        <Avatar src={user.avatarUrl} alt="Avatar" />
        <NameWrapper>{user.displayName}</NameWrapper>
      </HeaderWrapper>
      <Form action={actionUrl} method="post" noValidate>
        <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
        {form.render()}
        <Divider />
        <MessageWrapper>
          To update your other details or delete your account, please contact
          us.
        </MessageWrapper>
        <FormActionsWrapper>
          <Button type="submit">Save profile</Button>
          {overlay && (
            <Button
              type="button"
              kind="secondary"
              onClick={(e) => {
                e.preventDefault();
                requestClose({ skipDirtyFormCheck: true });
              }}
            >
              Cancel
            </Button>
          )}
        </FormActionsWrapper>
      </Form>
    </>
  );

  if (overlay) {
    return rendered;
  }

  return (
    <ContentWrapper>
      <Container>{rendered}</Container>
    </ContentWrapper>
  );
}

export default UserProfileView;
